import React, { useEffect, useState } from "react";

export function useLocalStorage(key, initialValue) {
  const [value, setValue] = useState(() => {
    const jsonValue = localStorage.getItem(key);

    //if we have something stored in local storage, then parse it.
    if (jsonValue != null) return JSON.parse(jsonValue);

    //if we dont have something stored in local storage, 
    //and our initialvalue is a function, then invoke, or just return it otherwise.
    if (typeof initialValue === "function") {
      return initialValue();
    } else {
      return initialValue;
    }
  });

  //store value in local storage every time it changes.
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}