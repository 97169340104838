import React, { useEffect, useState } from "react";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import classes from "./page.module.css";
import MyButton from "../components/MyButton";
//import { redirect } from "react-router-dom";
import { generateRandomString } from "../commons/utilities";
import { getLMSLink_API } from "../api/api";

function LMS() {
  const { authenticationStatus, updateAuthenticationStatus } =
    useAuthenticationContext();
  const [getLMSLinkResponse, setGetLMSLinkResponse] = useState(null);
  const [isLMSLinkAvailable, setIsLMSLinkAvailable] = useState(false);
  const [lmsLink, setLmsLink] = useState(null);
  const [isLMSLinkError, setIsLMSLinkError] = useState(false);
  const [lmsLinkErrorMessage, setLMSLinkErrorMessage] = useState(null);

  const [loggedIn, setLoggedIn] = useState(authenticationStatus.authenticated);
  const [isBusy, setIsBusy] = useState(false);
  const clientID = "testclient";
  let redirectURI =
    process.env.REACT_APP_MODE === "dev"
      ? "http://localhost:3000/login"
      : "https://ediscool.com/login";
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  useEffect(() => {
    if (getLMSLinkResponse) {
      setIsBusy(false);
      console.log(`getLMSLinkResponse: ${getLMSLinkResponse}`);
      if (getLMSLinkResponse.success && getLMSLinkResponse.url) {
        console.log(`***** LMS URL: ${getLMSLinkResponse.url}`);
        setLmsLink(getLMSLinkResponse.url);
        setIsLMSLinkAvailable(true);
        setIsLMSLinkError(false);
        setLMSLinkErrorMessage(null);

        //console.log(`Redirecting user to: ${getLMSLinkResponse.url}`);
        //window.location.href = getLMSLinkResponse.url;
      } else {
        setIsLMSLinkError(true);
        if (!getLMSLinkResponse.success) {
          console.log(
            `Failed to get LMS Link for user, error message: ${getLMSLinkResponse.errorMessage}`
          );
          setLMSLinkErrorMessage(
            `Failed to get LMS Link for user, error message: ${getLMSLinkResponse.errorMessage}`
          );
        } else {
          console.log(`LMS URL is NULL.`);
          setLMSLinkErrorMessage(`LMS URL is NULL.`);
        }
      }
      setGetLMSLinkResponse(null);
    }
  }, [getLMSLinkResponse]);

  const getLMSLink = () => {
    setGetLMSLinkResponse(null);
    setIsLMSLinkAvailable(false);
    setIsLMSLinkError(false);
    getLMSLink_API(authenticationStatus.username, setGetLMSLinkResponse);
    setIsBusy(true);

    return;

    if (0) {
      console.log(`should never come here....`);
      console.log("Redirecting to LMS");
      //const oauthAuthorize = https://www.ediscool.com/api/oauth/authorize";
      /*const oauthAuthorize =
      process.env.REACT_APP_MODE === "dev"
        ? "http://localhost:4000/api/oauth/authorize"
        : "https://www.ediscool.com/api/oauth/authorize";
      */
      const oauthAuthorize =
        process.env.REACT_APP_MODE === "dev"
          ? "api/oauth/authorize"
          : "api/oauth/authorize";

      const moodleRedirectUri =
        "https://lms1.ediscool.com/moodle/admin/oauth2callback.php";
      console.log(
        "Trying to navigate (full page) to backend's authorize endpoint and then - hopefully - redirect URI to Moodle's."
      );
      const wantsurl = "https://lms1.ediscool.com/moodle/";
      const randomSessionKey = generateRandomString(10);
      console.log(`randomSessionKey: ${randomSessionKey}`);
      const state = encodeURIComponent(
        `/auth/oauth2/login.php?wantsurl=${encodeURIComponent(
          wantsurl
        )}&sesskey=${randomSessionKey}&id=1&scope=openid profile email&username=${
          authenticationStatus.username
        }`
      );

      const params = new URLSearchParams({
        response_type: "code",
        client_id: clientID,
        redirect_uri: moodleRedirectUri,
        state: state,
      });

      // Trigger full-page navigation to the backend's /authorize endpoint
      const url = `${oauthAuthorize}?${params.toString()}`;

      //console.log(`Redirecting user to: ${url}`);
      //window.location.href = url;

      console.log(`Fetching URL: ${url}`);
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      /*
    if (process.env.REACT_APP_MODE === "production") {
      console.log(`Fetching URL: ${url}`);
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
    } else {
      console.log(`Redirecting user to: ${url}`);
      window.location.href = url;
    }
    */
    }
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        {loggedIn ? (
          <>
            <div>
              User is logged in, and should be able to redirect to Moodle's LMS
            </div>
            <MyButton
              variant={"primary"}
              title={"Get LMS Link"}
              onClickHandler={() => {
                console.log("Get LMS Link");
                getLMSLink();
              }}
              disabled={isBusy}
            />
            {isLMSLinkAvailable && lmsLink && (
              <MyButton
                variant={"primary"}
                title={"Go To LMS"}
                onClickHandler={() => {
                  console.log(`******** Redirecting user to: ${lmsLink}`);
                  window.location.href = lmsLink;
                }}
                disabled={isBusy}
              />
            )}
            {isLMSLinkError && lmsLinkErrorMessage && (
              <div>{lmsLinkErrorMessage}</div>
            )}
          </>
        ) : (
          <div>User is not logged in.</div>
        )}
      </div>
    </div>
  );
}

export default LMS;
