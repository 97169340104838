import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
//import { Navigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
//import BBBHandles from "./pages/BBBHandles";
import MainSections from "./pages/MainSections";
import Playground from "./pages/Playground";
import LMS from "./pages/LMS";
import Login from "./pages/Login";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import LoginTest from "./pages/LoginTest";

function App() {
  
  return (
    <Router>
      <div className="app_container">
        <Navbar />
        {/*<MainSections />*/}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lms" element={<LMS />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logintest" element={<LoginTest />} />
          {/*<Route path="/bbb" element={<BBBHandles />} />*/}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
