import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import TextField from "@mui/material/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import { userLoginAPI, UserSignUpAPI } from "../api/api";
import classes from "./page.module.css";
import "./login.css";

import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";

import ModalInfo from "../components/modals/ModalInfo";
import ModalAlert from "../components/modals/ModalAlert";
import { use } from "i18next";

function Login() {
  //const { userRef } = useRef("");
  const navigate = useNavigate();

  const { authenticationStatus, updateAuthenticationStatus } =
    useAuthenticationContext();

  const [user, setUser] = useState("");
  const [kilma, setKilma] = useState("");
  const [isInvalidKilma, setIsInvalidKilma] = useState(false);
  const [showKilma, setShowKilma] = useState(false);
  const [signUpResponse, setSignUpResponse] = useState(null);
  const [loginResponse, setLoginResponse] = useState(null);
  const [loggedIn, setLoggedIn] = useState(authenticationStatus.authenticated);

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");

  //const [showModalInfo, setShowModalInfo] = useState(false);
  //const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");
  const [isBusy, setIsBusy] = useState(false);

  const addNewUser = false;

  useEffect(() => {
    if (addNewUser) {
      const username = "ahmed";
      const password = "ahmed@1234";
      const email = "ahmed12345@gmail.com";
      const role = "student";
      const kilma = hashKilma(password);
      UserSignUpAPI(username, email, role, kilma, setSignUpResponse);
      setIsBusy(true);
    }
  }, []);

  useEffect(() => {
    if (signUpResponse) {
      setIsBusy(false);
      console.log(
        `Got a new response for signing-up a user: ${JSON.stringify(
          signUpResponse
        )}`
      );
      if (signUpResponse.success) {
        console.log(
          `Signing-up for ${signUpResponse.username} is successful!!`
        );
      } else {
        let message = `Sign-up failed`;
        setModalAlertMessage(message);
        setShowModalAlert(true);
      }
    }
  }, [signUpResponse]);

  useEffect(() => {
    if (loginResponse) {
      setIsBusy(false);
      console.log(
        `Got a new response for logging-in a user: ${JSON.stringify(
          loginResponse
        )}`
      );
      if (loginResponse.success) {
        console.log(`Logging-in for ${loginResponse.username} is successful!!`);
        setLoggedIn(true);
        updateAuthenticationStatus({
          authenticated: true,
          username: loginResponse.username,
          userId: loginResponse.userId,
          role: loginResponse.role,
          errorMessage: null,
        });

        console.log("about to navigate to home page");
        navigate("/");
      } else {
        let message = `Log-in failed`;
        setModalAlertMessage(message);
        setShowModalAlert(true);
      }
    }
  }, [loginResponse]);

  const hashKilma = (kilma) => {
    const hash = CryptoJS.SHA256(kilma);
    return hash.toString(CryptoJS.enc.Hex);
  };

  const handleSubmitLogIn = async (e) => {
    e.preventDefault();
    setLoggedIn(false);

    let username = user.trim(); //remove whitespace before and after user (to make sure no duplicate user names)
    try {
      const hashedKilma = hashKilma(kilma);
      userLoginAPI(username, hashedKilma, setLoginResponse);
      setIsBusy(true);
    } catch (error) {
      let message = `Error in hashing the kilma: (${error}).`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
    }

    setUser("");
    setKilma("");
  };

  const closeAlertModel = () => {
    setShowModalAlert(false);
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        {loggedIn ? (
          <div className="login_container">You're already logged in.</div>
        ) : (
          <div className="login_container">
            <h2>Log In</h2>
            <form className="login_form" onSubmit={handleSubmitLogIn}>
              <div className="login_element">
                <TextField
                  type="text"
                  id="username"
                  className="input_element"
                  label="User Name"
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                />
              </div>
              <div className="login_element">
                <TextField
                  id="kilma"
                  label="Password"
                  rows={1}
                  type={showKilma ? "text" : "password"}
                  value={kilma ? kilma : ""}
                  error={isInvalidKilma}
                  onChange={(e) => setKilma(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          onClick={() => {
                            setShowKilma(!showKilma);
                          }}
                        >
                          {showKilma ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              </div>
              <div className="button_div">
                <MyButton
                  variant={"primary"}
                  onClickHandler={handleSubmitLogIn}
                  title={"Sign In"}
                />
              </div>

              <div className="button_div">
                <a href="/forgotPassword">Forgot Password?</a>
              </div>
            </form>
          </div>
        )}
        {showModalAlert && (
          <ModalAlert
            show={showModalAlert}
            alertMessage={modalAlertMessage}
            handleClose={closeAlertModel}
          />
        )}

        {isBusy && <MySpinner />}
      </div>
    </div>
  );
}

export default Login;
