import React from "react";
import "../../styles.css";

function Footer() {
  return (
    <div className="footer">
      <span>O2lob &copy; 2024. </span>
      <span>All Copy Rights Reserved For RyadaTech. LLC, Oman</span>
    </div>
  );
}

export default Footer;
