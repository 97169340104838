import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";
import {
  login_API,
  getToken_API,
  accessProtectedRoute_API,
  testBackend_API,
  getUserInfo_API,
} from "../api/api";

function LoginTest() {
  const [isBusy, setIsBusy] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  const [isWaitingForToken, setIsWaitingForToken] = useState(false);
  const [getTokensResponse, setGetTokensResponse] = useState(null);
  const [getProtectedRouteResponse, setGetProtectedRouteResponse] =
    useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAccessGranted, setIsAccessGranted] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [protectedData, setProtectedData] = useState(null);
  const [accessDeniedMessage, setAccessDeniedMessage] = useState(null);
  const [getTestBackendResponse, setGetTestBackendResponse] = useState(null);
  const [authCode, setAuthCode] = useState(null);
  const [getUserInfoResponse, setGetUserInfoResponse] = useState(null);
  const location = useLocation();
  //const history = useHistory();
  const username = "testuser";
  const password = "password";
  const clientID = "testclient";
  let redirectURI =
    process.env.REACT_APP_MODE === "dev"
      ? "http://localhost:3000/login"
      : "https://ediscool.com/login";
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  useEffect(() => {
    console.log(
      `Login page is loaded, clientID: ${clientID}, and redirectURI: ${redirectURI}`
    );
  }, []);

  useEffect(() => {
    if (getUserInfoResponse !== null) {
      console.log(
        `Got a new getUserInfo response: ${JSON.stringify(
          getUserInfoResponse
        )}.`
      );
      setIsBusy(false);
      setGetUserInfoResponse(null);
    }
  }, [getUserInfoResponse]);
  useEffect(() => {
    if (getTestBackendResponse !== null) {
      console.log(
        `Got a new getTestBackend response: ${JSON.stringify(
          getTestBackendResponse
        )}.`
      );
      setIsBusy(false);
      setGetTestBackendResponse(null);
    }
  }, [getTestBackendResponse]);

  useEffect(() => {
    // Check if the URL contains an authorization code
    const params = new URLSearchParams(location.search);
    const authorizationCode = params.get("code");
    console.log(
      `^^^^^^^^ ^^^^^^ Authorization code: ${authorizationCode} ^^^^^^^^ ^^^^^^`
    );

    setAuthCode(authorizationCode);

    if (authorizationCode) {
      if (isWaitingForToken) {
        console.log(
          `Authorization code received: ${authorizationCode}, but we are already waiting for a token.`
        );
      } else {
        console.log(
          `Authorization code received: ${authorizationCode}, proceed to exchange it for a token.`
        );

        // If there's an authorization code, handle the OAuth callback
        setIsBusy(true);
        setIsWaitingForToken(true);
        setGetTokensResponse(null);
        console.log(
          `Before calling (token) endpoint, clientID: ${clientID}, clientSecret: ${clientSecret}`
        );

        getToken_API(
          username,
          clientID,
          clientSecret,
          redirectURI,
          authorizationCode,
          setGetTokensResponse
        );
        //setIsProcessing(true);
        //exchangeAuthorizationCodeForToken(authorizationCode);
      }
    } else {
      console.log(
        `No authorization code received, and waiting for one is ${isWaitingForToken}.`
      );
    }
  }, [location]);

  useEffect(() => {
    if (getTokensResponse !== null) {
      if (isWaitingForToken) {
        console.log(
          `Got a new getTokens response: ${JSON.stringify(getTokensResponse)}.`
        );
        if (getTokensResponse.access_token) {
          console.log("Access token:", getTokensResponse.access_token);
          // Store the access token securely
          sessionStorage.setItem(
            "ediscoolAccessToken",
            getTokensResponse.access_token
          );
          console.log(`******* successful login *******`);
          setIsLoggedIn(true);
        }
        setIsBusy(false);
        setIsWaitingForToken(false);
        setGetTokensResponse(null);
      } else {
        console.log(
          `Got a new getTokens response: ${JSON.stringify(
            getTokensResponse
          )}, but not waiting for one, so ignore it.`
        );
      }
    }
  }, [getTokensResponse]);

  useEffect(() => {
    if (loginResponse !== null) {
      console.log(
        `Got a new login response: ${JSON.stringify(loginResponse)}.`
      );
      if (loginResponse.success) {
        console.log(
          `Trying to navigate to this URI: ${loginResponse.redirectURI}`
        );
        window.location.href = loginResponse.redirectURI;
      }
    }

    setIsBusy(false);
    setLoginResponse(null);
  }, [loginResponse]);

  useEffect(() => {
    if (getProtectedRouteResponse !== null) {
      /*console.log(
        `Got a new getProtectedRoute response: ${JSON.stringify(
          getProtectedRouteResponse
        )}.`
      );*/
      if (getProtectedRouteResponse.success) {
        setIsAccessGranted(true);
        setProtectedData(getProtectedRouteResponse.data.message);
      } else {
        setIsAccessDenied(true);
        setAccessDeniedMessage(getProtectedRouteResponse.message);
      }
    }

    setIsBusy(false);
    setGetProtectedRouteResponse(null);
  }, [getProtectedRouteResponse]);

  const testProtectedEndpoint = () => {
    console.log("Testing protected endpoint.");
    setIsAccessGranted(false); // Reset the access granted flag
    setProtectedData(null); // Reset the protected data
    setIsAccessDenied(false);
    setAccessDeniedMessage(null);

    accessProtectedRoute_API(setGetProtectedRouteResponse);
    setIsBusy(true);
  };

  const redirectToMoodle = () => {
    const accessToken = sessionStorage.getItem("ediscoolAccessToken");
    //const moodleAuthorizationURL = `https://lms1.ediscool.com/moodle/oauth2/authorize`;
    const moodleRedirectUri =
      "https://lms1.ediscool.com/moodle/admin/oauth2callback.php";

    const state = crypto.getRandomValues(new Uint32Array(4)).join("-");
    sessionStorage.setItem("oauthState", state);

    console.log(
      `^^^^^^^^ ^^^^^^ Stored auth code is: ${authCode} ^^^^^^^^ ^^^^^^`
    );

    const authorizationUrl = `${moodleRedirectUri}?response_type=code&client_id=${clientID}&username=${username}&code=${authCode}&state=${state}`;
    window.location.href = authorizationUrl;

    /*const authorizationUrl = `${moodleRedirectUri}?response_type=code&client_id=${clientID}&redirect_uri=${encodeURIComponent(
      redirectURI
    )}&state=${state}&code=${authCode}`;*/
    /*const authorizationUrl = `${moodleAuthorizationURL}?response_type=code&client_id=${clientID}&redirect_uri=${encodeURIComponent(
      moodleRedirectUri
    )}&state=${state}&username=${encodeURIComponent(
      username
    )}&password=${encodeURIComponent(password)}`;*/
  };

  const newRedirectToMoodle = () => {
    const moodleRedirectUri =
      "https://lms1.ediscool.com/moodle/admin/oauth2callback.php";
    console.log("Trying to log in and set the redirect URI to Moodle's.");
    const state = crypto.getRandomValues(new Uint32Array(4)).join("-");
    setIsBusy(true);
    login_API(
      username,
      clientID,
      moodleRedirectUri,
      state,
      password,
      setLoginResponse
    );
  };

  const newestRedirectToMoodle = () => {
    const oauthAuthorize = "https://www.ediscool.com/api/oauth/authorize";
    const moodleRedirectUri =
      "https://lms1.ediscool.com/moodle/admin/oauth2callback.php";
    console.log(
      "Trying to navigate (full page) to backend's authorize endpoint and then - hopefully - redirect URI to Moodle's."
    );
    const wantsurl = "https://lms1.ediscool.com/moodle/";
    const state = encodeURIComponent(
      `/auth/oauth2/login.php?wantsurl=${encodeURIComponent(
        wantsurl
      )}&sesskey=0eUGsPLpCt&id=1&scope=openid profile email&username=${username}`
    );

    /*const state_without_session_key = encodeURIComponent(
      `/auth/oauth2/login.php?wantsurl=${encodeURIComponent(
        wantsurl
      )}&id=1&scope=openid profile email&username=${username}`
    );*/
    //const state = `/auth/oauth2/login.php?wantsurl=${encodeURIComponent(wantsurl)}&sesskey=0eUGsPLpCt&id=1&scope=openid profile email`;

    const params = new URLSearchParams({
      response_type: "code",
      client_id: clientID,
      redirect_uri: moodleRedirectUri,
      state: state,
      username: username,
      password: password,
    });

    // Trigger full-page navigation to the backend's /authorize endpoint
    const url = `${oauthAuthorize}?${params.toString()}`;
    console.log(`Redirecting user to: ${url}`);
    window.location.href = url;
  };

  const getUserInfo = () => {
    console.log("Trying to get user info.");
    setIsBusy(true);
    getUserInfo_API(setGetUserInfoResponse);
  };

  return (
    <div>
      This is login page.
      <MyButton
        variant={"primary"}
        title={"Test Log In"}
        onClickHandler={() => {
          console.log("Log In button clicked.");
          const state = crypto.getRandomValues(new Uint32Array(4)).join("-");
          setIsBusy(true);
          login_API(
            username,
            clientID,
            redirectURI,
            state,
            password,
            setLoginResponse
          );
        }}
        disabled={false}
      />
      <MyButton
        variant={"primary"}
        title={"Test Backend"}
        onClickHandler={() => {
          setIsBusy(true);
          testBackend_API(setGetTestBackendResponse);
        }}
        disabled={false}
      />
      {isLoggedIn && (
        <MyButton
          variant={"primary"}
          title={"Test Protected Endpoint"}
          onClickHandler={() => {
            testProtectedEndpoint();
          }}
          disabled={false}
        />
      )}
      {isAccessGranted && (
        <MyButton
          variant={"primary"}
          title={"Test userInfo Endpoint"}
          onClickHandler={() => {
            console.log("Test userInfo button clicked.");
            getUserInfo();
          }}
        />
      )}
      {isAccessDenied && (
        <div>Access is denied, with message: {accessDeniedMessage}</div>
      )}
      {isAccessGranted && (
        <div>Access granted. Protected data: {protectedData}</div>
      )}
      {isAccessGranted && (
        <MyButton
          variant={"primary"}
          title={"Redirect to Moodle"}
          onClickHandler={() => {
            console.log("Redirect to Moodle button clicked.");
            //newRedirectToMoodle();
            newestRedirectToMoodle();
          }}
          disabled={false}
        />
      )}
      {/*{isAccessGranted && (
        <MyButton
          variant={"secondary"}
          title={"Another button"}
          onClickHandler={() => {
            console.log("Another button clicked.");
          }}
        />
      )}*/}
      {isBusy && <MySpinner message={"Loging In..."} />}
    </div>
  );
}

export default LoginTest;
