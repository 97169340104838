import React, { useEffect } from "react";
import classes from "./page.module.css";

function Home() {
  useEffect(() => {
    console.log(`Mode of operation: ${process.env.REACT_APP_MODE}`);
    console.log(`Compilation time: ${process.env.REACT_APP_COMPILATION_TIME}`);
  }, []);
  return (
    <div className={classes.main_div}>
      This is the home page.
      <div>
        This is EdIsCool frontend application. Compilation time:{" "}
        {process.env.REACT_APP_COMPILATION_TIME}
      </div>
    </div>
  );
}

export default Home;
