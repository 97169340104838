import React, { useState, useEffect } from "react";
import { createContext, useContext } from "react";
//import { fetchAuthenticationStatusAPI } from "../api/api.js";
import { useSessionStorage } from "../hooks/useSessionStorage";
import { useLocalStorage } from "../hooks/useLocalStorage";

//create empty context
const AuthenticationContext = createContext({});

const authStorage = "ediscoolAuth";

const USER_ROLES = {
  ADMIN: "admin",
  TEACHER: "teacher",
  TEATCHING_ASSISTANT: "teaching_assistant",
  STUDENT: "student",
};

export function useAuthenticationContext() {
  return useContext(AuthenticationContext);
}

export function AuthenticationContextProvider({ children }) {
  /*const [authenticationStatus, setAuthenticationStatus] = useSessionStorage(
    authStorage,
    {
      authenticated: false,
      username: null,
      role: null,
      errorMessage: null,
    }
  );*/

  const [authenticationStatus, setAuthenticationStatus] = useLocalStorage(
    authStorage,
    {
      authenticated: false,
      username: null,
      userId: null,
      role: null,
      errorMessage: null,
    }
  );


  // Sync with session storage on component mount
  useEffect(() => {
    //const storedAuthStatus = sessionStorage.getItem(authStorage);
    const storedAuthStatus = localStorage.getItem(authStorage);
    if (storedAuthStatus) {
      try {
        const parsedAuthStatus = JSON.parse(storedAuthStatus);
        setAuthenticationStatus(parsedAuthStatus);
      } catch (e) {
        console.error("Failed to parse session storage data:", e);
      }
    }
  }, [setAuthenticationStatus]);

  //get authentication status after component is mounted
  //useEffect(() => {
  //  fetchAuthenticationStatusAPI(setAuthenticationStatus);
  //}, []);

  //console.log when authenticationStatus changes
  useEffect(() => {
    console.log(
      `"""""" Authentication status is changed: ${JSON.stringify(
        authenticationStatus
      )}`
    );
  }, [authenticationStatus]);

  /*const getAuthenticationStatus = () => {
    console.error("you sould get authentication status from backend.");
    //fetchAuthenticationStatusAPI(setAuthenticationStatus);
  };*/

  const updateAuthenticationStatus = (authStatus) => {
    setAuthenticationStatus((prev) => {
      return {
        authenticated: authStatus.authenticated
          ? authStatus.authenticated
          : false,
        username: authStatus.username ? authStatus.username : prev.username,
        userId: authStatus.userId ? authStatus.userId : prev.userId,
        role: authStatus.role ? authStatus.role : prev.role,
        errorMessage: authStatus.errorMessage ? authStatus.errorMessage : null,
      };
    });
  };

  const resetAuthentication = () => {
    setAuthenticationStatus({
      authenticated: false,
      username: null,
      userId: null,
      role: null,
      errorMessage: null,
    });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        authenticationStatus,
        USER_ROLES,
        updateAuthenticationStatus,
        resetAuthentication,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}
