import React, { useEffect, useState } from "react";
import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import { testBackend_API, accessProtectedRoute_API } from "../api/api";
import classes from "./page.module.css";

function Playground() {
  const { authenticationStatus, updateAuthenticationStatus } =
    useAuthenticationContext();
  const [loggedIn, setLoggedIn] = useState(authenticationStatus.authenticated);
  const [getTestBackendResponse, setGetTestBackendResponse] = useState(null);
  const [getProtectedRouteResponse, setGetProtectedRouteResponse] =
    useState(null);
  const [isAccessGranted, setIsAccessGranted] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [protectedData, setProtectedData] = useState(null);
  const [accessDeniedMessage, setAccessDeniedMessage] = useState(null);
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    if (getTestBackendResponse !== null) {
      console.log(
        `Got a new getTestBackend response: ${JSON.stringify(
          getTestBackendResponse
        )}.`
      );
      setIsBusy(false);
      setGetTestBackendResponse(null);
    }
  }, [getTestBackendResponse]);

  useEffect(() => {
    if (getProtectedRouteResponse !== null) {
      /*console.log(
        `Got a new getProtectedRoute response: ${JSON.stringify(
          getProtectedRouteResponse
        )}.`
      );*/
      if (getProtectedRouteResponse.success) {
        setIsAccessGranted(true);
        setProtectedData(getProtectedRouteResponse.data.message);
      } else {
        setIsAccessDenied(true);
        setAccessDeniedMessage(getProtectedRouteResponse.message);
      }
    }

    setIsBusy(false);
    setGetProtectedRouteResponse(null);
  }, [getProtectedRouteResponse]);

  const testProtectedEndpoint = () => {
    console.log("Testing protected endpoint.");
    setIsAccessGranted(false); // Reset the access granted flag
    setProtectedData(null); // Reset the protected data
    setIsAccessDenied(false);
    setAccessDeniedMessage(null);

    accessProtectedRoute_API(setGetProtectedRouteResponse);
    setIsBusy(true);
  };

  return (
    <div className={classes.main_div}>
      This is Playground.
      <div>
        <MyButton
          variant={"primary"}
          title={"Test Backend"}
          onClickHandler={() => {
            setIsBusy(true);
            testBackend_API(setGetTestBackendResponse);
          }}
          disabled={false}
        />
      </div>
      {loggedIn && (
        <div>
          <MyButton
            variant={"primary"}
            title={"Test Protected Endpoint"}
            onClickHandler={() => {
              testProtectedEndpoint();
            }}
            disabled={false}
          />
        </div>
      )}
      {isAccessDenied && (
        <div>Access is denied, with message: {accessDeniedMessage}</div>
      )}
      {isAccessGranted && (
        <div>Access granted. Protected data: {protectedData}</div>
      )}
      {isBusy && <MySpinner message={"Busy..."} />}
    </div>
  );
}

export default Playground;
